import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Sp1 from "./components/Sp1";
import Sp2 from "./components/Sp2";
import Sp3 from "./components/Sp3";
import Zone4 from "./components/Zone4";
import Zone5 from "./components/Zone5";
import Zone6 from "./components/Zone6";
import Zone7 from "./components/Zone7";
import Zone8 from "./components/Zone8";
import Zone9 from "./components/Zone9";
import Zone10 from "./components/Zone10";

function App() {

  return (
    <>
      <BrowserRouter>
        <div style={{
          display: "flex",
          background: 'black',
          padding: '5px 0 5px 5px',
          fontSize: '20px'
        }}>
          <div style={{ margin: '10px' }}>
            <NavLink to="/" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Water Treatment Plant 
            </NavLink>
          </div>
          <div style={{ margin: '10px' }}>
            <NavLink to="/about" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              About
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Sp1" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Saidabad-I
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Sp2" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Saidabad-II
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Sp3" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Saidabad-III
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Zone4" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Gondoppur
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Zone5" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              SKWTP
            </NavLink>
          </div>
		  
          <div style={{ margin: '10px' }}>
            <NavLink to="/Zone7" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Padma
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Zone6" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
             16_union
            </NavLink>
          </div>


        </div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
		  /*
         
		 */
          <Route exact path="/Sp1" element={<Sp1 />} />
          <Route exact path="/Sp2" element={<Sp2 />} />
          <Route exact path="/Sp3" element={<Sp3 />} />
          <Route exact path="/Zone4" element={<Zone4 />} />
          <Route exact path="/Zone5" element={<Zone5 />} />
          <Route exact path="/Zone6" element={<Zone6 />} />
          <Route exact path="/Zone7" element={<Zone7 />} />
		  <Route exact path="/Zone8" element={<Zone8 />} />
          <Route exact path="/Zone9" element={<Zone9 />} />
          <Route exact path="/Zone10" element={<Zone10 />} />
		 
		  
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
