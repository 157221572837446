//import React from "react";
import React, { Component } from "react";
import { MapContainer, Map, TileLayer, Marker, Popup, LayersControl, Circle, Polygon, LayerGroup, Tooltip } from 'react-leaflet';
import './App.css';
import { Icon } from "leaflet";
import { useMap,useMapEvent,FeatureGroup,GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { useEffect } from 'react';
//import arcades from './arcades.json'
//import arcades1 from './arcades1.json'
import zone from './SLS_point.json'
import SWTP_Valve from "./data/water_treatment/SWTP_Valve.json";
import SWTP_pipe from "./data/water_treatment/SWTP_pipe.json";
import SKWTP_dtw from "./data/water_treatment/SKWTP_dtw.json";
import SWTP_loc from "./data/water_treatment/SWTP_loc.json";
import SWTP_Road from "./data/water_treatment/SWTP_Road.json";

import "./styles.css";
import "leaflet-arrowheads";
//import { GeoJSON } from 'react-leaflet/GeoJSON'

import arcades10 from './sls_trunk.json'
//delete L.Icon.Default.prototype._getIconUrl;

import {
	bz61, bz62,bz63,bz64, bz65, bz66,bz67,bz69,bz610,bz68,
Padma_Booster,
Savar_IRP_I,
Saver_IRP_II,
Saidabad_plant,
Saidabad_Intake,
Gondoppur_Plant,
Gondoppur_Intake,
Padma_plant,
Padma_intake,
Saidabad_P3_Intake,
Chadnighat_Plant,
epcotCenter,
magicKingdomCenter,
} from './data1';
//import * as DTWall from "./data/Biometric.json";
import * as data_z1 from "./data/data_z1.json";

import * as data_z2 from "./data/data_z1.json";
import * as data_z3 from "./data/SLS_point.json";
import * as data_z4 from "./data/data_z1.json";
import * as data_z5 from "./data/data_z1.json";
import * as data_z6 from "./data/data_z1.json";
import * as data_z7 from "./data/data_z1.json";
import * as data_z8 from "./data/data_z1.json";
import * as data_z9 from "./data/data_z1.json";
import * as data_z10 from "./data/data_z1.json";
//import nationalParks from "./national-parks.json";
import useSwr from "swr";

const fetcher = (...args) => fetch(...args).then(response => response.json());


const skater = new Icon({
  iconUrl: "/giphy_cir.gif",
  iconSize: [15, 15]
});

const Pin = new Icon({
  iconUrl: "/Pin.gif",
  iconSize: [15, 15]
});

const DTW = new Icon({
  iconUrl: "/DTW.gif",
  iconSize: [15, 15]
});


const SLS_lift1 = new Icon({
  iconUrl: "/dance.gif",
  iconSize: [30, 55]
});
const SLS_lift = new Icon({
  iconUrl: "/pride-rainbow.gif",
  iconSize: [25, 25]
});


//import * as xxx from "./data/bz6.json";

//var legend1 = legend ;
//import Header from "./components/Header";
//import Content from "./components/Content";
//import { Icon } from "leaflet";
//import * as parkData1 from "./data/skateboard-parks.json";



const GetCoordinates = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const info = L.DomUtil.create('div', 'legend');

    const positon = L.Control.extend({
      options: {
        position: 'bottomright'
      },

      onAdd: function () {
        info.textContent = 'Click on map';
        return info;
      }
    })

    map.on('click', (e) => {
      info.textContent = e.latlng;
    })

	map.addControl(new positon());

  }, [map])


  return null

}

//import * as DTW2 from "./data/output.js";
//var parkData = parkData1;
const DTW1 = data_z1;
const DTW2 = data_z1;
const DTW33 = data_z3;
const DTW4 = data_z1;
const DTW5 = data_z1;
const DTW6 = data_z1;
const DTW7 = data_z1;
const DTW9 = data_z1;
const DTW8 = data_z1;
const DTW10 = data_z1;
const xxxx= arcades10

//const DTW = DTWall;
//console.log(parkData)
const ControllingGroup = () => {
  const map = useMapEvent({
    layeradd() {
      let bounds = new L.LatLngBounds();
      map.eachLayer(function (layer) {
        if (layer instanceof L.FeatureGroup) {
          bounds.extend(layer.getBounds());
        }
      });

      if (bounds.isValid()) {
        map.flyToBounds([bounds]);
      }
    },
  });

  return null;
};

const Cz1 = { color: "green" };
const Cz2 = { color: "blue" };
const Cz3 = { color: "green" };
const Cz4 = { color: "magenta" };
const Cz5 = { color: "red" };
const Cz6 = { color: "Orange" };
const Cz7 = { color: "blue" };
const Cz8 = { color: "green" };
const Cz9 = { color: "magenta" };
const Cz10 = { color: "Orange" };

function Zone6() {

/*
const mapRef = useRef();

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if ( !map ) return;

    const parksGeoJson = new L.GeoJSON(nationalParks, {
      onEachFeature: (feature = {}, layer) => {
        const { properties = {} } = feature;
        const { Name } = properties;

        if ( !Name ) return;

        layer.bindPopup("<p>${Name}</p>");
      }
    });

    parksGeoJson.addTo(map);
  }, [])
*/
  // const arr = DTW2;

  //const result = arr.filter(park => park.properties.ZONEID === 5);



  //const DTW = DTW1.filter(park => park.properties.name === "Gulshan-7")
  return (
      

    
    <MapContainer center={[23.734, 90.402]} zoom={13}  scrollWheelZoom={true}>
      <TileLayer
        url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
        maxZoom={20}
        subdomains={['mt1', 'mt2', 'mt3']}
		
      />

    <GetCoordinates />

		 
      {/*
      {DTW.features.map(park => (

        <Marker key={park.properties.OBJECTID} position={[park.properties.X,
        park.properties.Y]}>
          <Popup position={[park.properties.X,
          park.properties.Y]}>
            <div >
              <h1 style={{ color: "red" }}>Water ATM  Booth LOCATION!</h1>
              <h2>{park.properties.name}</h2>
              <h1 style={{ color: "red" }}>COPEN (10 AM to 6 PM )!</h1>
              <h2>{park.properties.LABEL}</h2>

            </div>

          </Popup>
        </Marker>
      ))}
	   //<Circle className="circle-magic-kingdom" center={magicKingdomCenter} radius={100} />
	     //<Circle className="circle-magic-kingdom" center={magicKingdomCenter1} radius={100} />
	  
    */}
			 
	
	
      <LayersControl position="topright">

        <LayersControl.BaseLayer  name="google street">

          <TileLayer
            url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />

        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer checked name="google satelite">

          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
	
			
        <LayersControl.Overlay checked name="Dasherkandi_STP">
         
		  <Circle className="circle-magic-kingdom" center={magicKingdomCenter} radius={200} />
		   </LayersControl.Overlay>
		   <LayersControl.Overlay checked name="pagla_STP">
         
		  <Circle className="circle-magic-kingdom" center={epcotCenter} radius={200} />

	
        </LayersControl.Overlay>	
				<LayersControl.Overlay  name="Transmission Line">
          <LayerGroup>			  
		 <GeoJSON attribution="&copy; credits due..." data={SWTP_pipe} /> 	      
			   </LayerGroup>
		   
	        </LayersControl.Overlay>	
				<LayersControl.Overlay  name="Road_WTP">
          <LayerGroup>			  
		 <GeoJSON attribution="&copy; credits due..." data={SWTP_Road} /> 	      
			   </LayerGroup>			  
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Location_WTP">
          <LayerGroup>
		   
            <Tooltip sticky>zone 6</Tooltip>
          {SWTP_loc.features.map((arcade1, index) => (
            <Marker
              key={arcade1.properties['start']}
              position={[arcade1.geometry.coordinates[1], arcade1.geometry.coordinates[0]]}icon={SLS_lift}>
              <Popup>
              
				
				<div >
                    <h1 style={{ color: "red" }}>WTP Name!</h1>
                    <h2>{arcade1.properties.Name}</h2>
                    <h1 style={{ color: "red" }}>!</h1>
                    <h2>{arcade1.properties.Pipe_Diameter}</h2>
					<a href={arcade1.properties.Nearby_Bui}><img src={arcade1.properties.Nearby_Bui } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
		  
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Valve_WTP">
          <LayerGroup>

            
            <Tooltip sticky>zone 6</Tooltip>
          {SWTP_Valve.features.map((arcade, index) => (
            <Marker
              key={arcade.properties['start']}
              position={[arcade.geometry.coordinates[1], arcade.geometry.coordinates[0]]}icon={skater}>
              <Popup>
                
				
				<div >
                    <h1 style={{ color: "red" }}>Valve Type!</h1>
                    <h2>{arcade.properties.Type}</h2>
                    <h1 style={{ color: "red" }}> Diameter (mm )!</h1>
                    <h2>{arcade.properties.Diameter}</h2>
					<a href={arcade.properties.Land_Mark}><img src={arcade.properties.Land_Mark } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay  checked name="DTW">
          <LayerGroup>
		  
            
            <Tooltip sticky>zone 6</Tooltip>
            {SKWTP_dtw.features.map((SLS, index) => (
            <Marker
              key={SLS.properties['start']}
              position={[SLS.geometry.coordinates[1], SLS.geometry.coordinates[0]]}icon={DTW}
            >
              <Popup>
                {SLS.properties.SLS_Name1}
                <br />
                {SLS.properties['SLS_Name1']}
				
				<div >
                    <h1 style={{ color: "red" }}>DTW Name!</h1>
                    <h2>{SLS.properties.status}</h2>
                    <h1 style={{ color: "red" }}>Zone!</h1>
                    <h2>{SLS.properties.Zone}</h2>
					
                  </div>
              </Popup>
            </Marker>
          ))}
			
			
          </LayerGroup>
		  
		  
        </LayersControl.Overlay>

        <LayersControl.Overlay  name="zone 4">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz64} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW4.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay  name="zone 5">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz65} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW5.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 6">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz66} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW6.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 7">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz67} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW7.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 9">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz69} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW9.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>



        <LayersControl.Overlay  name="zone 10">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz610} />
            <Tooltip sticky>zone 6</Tooltip>
			
            {DTW10.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
			
          </LayerGroup>
        </LayersControl.Overlay>



        <LayersControl.Overlay  name="zone 8">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz68} />
            <Tooltip sticky>zone 6</Tooltip>
			{DTW8.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}

          </LayerGroup>
        </LayersControl.Overlay>

      </LayersControl>
	  
  
 
<LayersControl position="topleft" collapsed={true}	 >

 
   <LayersControl.Overlay checked name="Saidabad Plant">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay>   


        <LayersControl.Overlay name="Saidabad_Intake" >
          <FeatureGroup>
		  
           <Polygon pathOptions={Cz1}  positions={Saidabad_Intake} />
		   
          	
		</FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay name="Saidabad_P3_Intake">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Saidabad_P3_Intake} />
			</FeatureGroup>
        </LayersControl.Overlay>
		
        <LayersControl.Overlay name="Saidabad_plant">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Saidabad_plant} />
			</FeatureGroup>
        </LayersControl.Overlay>		
		
	
		
     
	   <LayersControl.Overlay checked name="SKWTP">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay>   
	  
	  <LayersControl.Overlay name="Savar_IRP_I">
          <FeatureGroup>
            <Polygon pathOptions={Cz3} positions={Savar_IRP_I} />
			
	
          </FeatureGroup>
         </LayersControl.Overlay>
      



        <LayersControl.Overlay name="Saver_IRP_II">
          <FeatureGroup>
            <Polygon pathOptions={Cz4} positions={Saver_IRP_II} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	<LayersControl.Overlay checked name="Gondoppur_WTP">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay>   
	  
	  
	 <LayersControl.Overlay name="Gondoppur_Intake">
          <FeatureGroup>
            <Polygon pathOptions={Cz5} positions={Gondoppur_Intake} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Gondoppur_Plant">
          <FeatureGroup>
            <Polygon pathOptions={Cz6} positions={Gondoppur_Plant} />
          </FeatureGroup>
        </LayersControl.Overlay>
    
	<LayersControl.Overlay checked name="Padma_plant">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay>   
	  
	 <LayersControl.Overlay name="Padma_intake">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={Padma_intake} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Padma_plant ">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={Padma_plant } />
          </FeatureGroup>
        </LayersControl.Overlay>
     


		<LayersControl.Overlay name="Chadnighat_Plant">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={Chadnighat_Plant} />
          </FeatureGroup>
         </LayersControl.Overlay>

       	
		
      </LayersControl>

	  
	

<LayersControl position="topleft" collapsed={true}>

 <LayersControl.Overlay checked name="MODS ZONE">
        <FeatureGroup pathOptions={{ color: 'purple' }}>
          
        </FeatureGroup>
       </LayersControl.Overlay>  
 
        <LayersControl.Overlay name="Zone1">
          <FeatureGroup>
		  
           <Polygon pathOptions={Cz1} positions={bz61} />
          	
		</FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone2">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={bz62} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  <LayersControl.Overlay name="Zone3">
          <FeatureGroup>
            <Polygon pathOptions={Cz3} positions={bz63} />
          </FeatureGroup>
         </LayersControl.Overlay>
      



        <LayersControl.Overlay name="Zone4">
          <FeatureGroup>
            <Polygon pathOptions={Cz4} positions={bz64} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  
	 <LayersControl.Overlay name="Zone5">
          <FeatureGroup>
            <Polygon pathOptions={Cz5} positions={bz65} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone6">
          <FeatureGroup>
            <Polygon pathOptions={Cz6} positions={bz66} />
          </FeatureGroup>
        </LayersControl.Overlay>
    
	  
	  
	 <LayersControl.Overlay name="Zone7">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={bz67} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone8">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={bz68} />
          </FeatureGroup>
        </LayersControl.Overlay>
     


		<LayersControl.Overlay name="Zone9">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={bz69} />
          </FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay name="Zone10">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={bz610} />
          </FeatureGroup>
        </LayersControl.Overlay>
    
		
		
      </LayersControl>
	  
	  
		
	  
    <ControllingGroup />	  
	  
	  
	  
    </MapContainer >



  );
}






export default Zone6;
