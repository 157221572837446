import React from "react";
import { MapContainer, TileLayer, GeoJSON,Marker, Popup, FeatureGroup,LayersControl, Circle, Polygon, LayerGroup, Tooltip } from 'react-leaflet';
import './App.css';
import { useMap,useMapEvent } from 'react-leaflet';
import L from 'leaflet';

import { Icon } from "leaflet";
import south from './south.json'
import zone from './SLS_point.json'
import SWTP_Road from "./data/water_treatment/SWTP_Route.json";


//import './pic/img_girl.jpg';
//var pic = img_girl.jpg;
//import { FeatureGroup } from 'react-leaflet';
//import { EditControl } from "react-leaflet-draw";
import {
	bz61, bz62,bz63,bz64, bz65, bz66,bz67,bz69,bz610,bz68,
Padma_Booster,
Savar_IRP_I,
Saver_IRP_II,
Saidabad_plant,
Saidabad_Intake,
Gondoppur_Plant,
Gondoppur_Intake1,
Padma_plant,
Padma_intake,
Saidabad_plant1,
Saidabad_P3_Intake1,
Saidabad_P3_Intake,
Chadnighat_Plant,
epcotCenter,
magicKingdomCenter,
} from './data1';
//import React, { useState } from "react";
//import Header from "components/Header";


//import "leaflet/dist/leaflet.css";
//import "leaflet-draw/dist/leaflet.draw.css";
//import ExternalInfo from "components/ExternalInfo";



//import * as DTWall from "./data/Biometric.json";
import * as data_z1 from "./data/data_z1.json";
import * as data_z2 from "./data/data_z2.json";
import * as data_z3 from "./data/data_z3.json";
import * as data_z4 from "./data/data_z4.json";
import * as data_z5 from "./data/data_z5.json";
import * as data_z6 from "./data/data_z6.json";
import * as data_z7 from "./data/data_z7.json";
import * as data_z9 from "./data/data_z9.json";
import * as data_z10 from "./data/data_z10.json";

const skater1 = new Icon({
  iconUrl: "/Pin.gif",
  iconSize: [15, 15]
});
//import * as xxx from "./data/bz6.json";
const SLS_lift = new Icon({
  iconUrl: "/pride-rainbow.gif",
  iconSize: [25, 25]
});
const skater = new Icon({
  iconUrl: "/giphy_cir.gif",
  iconSize: [15, 15]
});






//var legend1 = legend ;
//import Header from "./components/Header";
//import Content from "./components/Content";
//import { Icon } from "leaflet";
//import * as parkData1 from "./data/skateboard-parks.json";

//import * as DTW2 from "./data/output.js";
//var parkData = parkData1;
const DTW1 = data_z1;
const DTW2 = data_z2;
const DTW3 = data_z3;
const DTW4 = data_z4;
const DTW5 = data_z5;
const DTW6 = data_z6;
const DTW7 = data_z7;
const DTW9 = data_z9;
const DTW10 = data_z10;

//const DTW = DTWall;
const ControllingGroup = () => {
  const map = useMapEvent({
    layeradd() {
      let bounds = new L.LatLngBounds();
      map.eachLayer(function (layer) {
        if (layer instanceof L.FeatureGroup) {
          bounds.extend(layer.getBounds());
        }
      });

      if (bounds.isValid()) {
        map.flyToBounds([bounds]);
      }
    },
  });

  return null;
};
//console.log(parkData)
const Cz1 = { color: "green" };
const Cz2 = { color: "blue" };
const Cz3 = { color: "green" };
const Cz4 = { color: "magenta" };
const Cz5 = { color: "red" };
const Cz6 = { color: "Orange" };
const Cz7 = { color: "blue" };
const Cz8 = { color: "green" };
const Cz9 = { color: "magenta" };
const Cz10 = { color: "Orange" };


function Zone6() {




  // const arr = DTW2;

  //const result = arr.filter(park => park.properties.ZONEID === 5);



  //const DTW = DTW1.filter(park => park.properties.name === "Gulshan-7")
  return (




    <MapContainer center={[23.712726, 90.545916]} zoom={12}  scrollWheelZoom={true}>
      <TileLayer
        url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
        maxZoom={20}
        subdomains={['mt1', 'mt2', 'mt3']}
      />


  
      {/*

      
        <EditControl
          position='topright'
          onEdited={this._onEditPath}
          onCreated={this._onCreate}
          onDeleted={this._onDeleted}
          draw={{
            rectangle: false
          }}
        />
      
      <GeoJSON attribution="&copy; credits due..." data={...} />
      {DTW.features.map(park => (

        <Marker key={park.properties.OBJECTID} position={[park.properties.X,
        park.properties.Y]}>
          <Popup position={[park.properties.X,
          park.properties.Y]}>
            <div >
              <h1 style={{ color: "red" }}>DEVICE LOCATION!</h1>
              <h2>{park.properties.name}</h2>
              <h1 style={{ color: "red" }}>CLUSTER DTW!</h1>
              <h2>{park.properties.LABEL}</h2>

            </div>

          </Popup>
        </Marker>
      ))}
    */}
      <LayersControl position="topright">

        <LayersControl.BaseLayer  name="google street">

          <TileLayer
            url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />

        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer checked name="google satelite">

          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Feature group">
          <Circle
            center={[51.51, -0.08]}
            pathOptions={{ color: 'green', fillColor: 'green' }}
            radius={100}
          />
        </LayersControl.Overlay>
		


				<LayersControl.Overlay checked name="Road_WTP">
          <LayerGroup>			  
		 <GeoJSON attribution="&copy; credits due..." data={SWTP_Road} /> 	      
			   </LayerGroup>			  
        </LayersControl.Overlay> 


<LayersControl.Overlay checked name="Saidabad_plant">
         
		  <Circle className="circle-magic-kingdom" center={Saidabad_plant1} radius={200} />
		   </LayersControl.Overlay>
		   
		   <LayersControl.Overlay checked name="Saidabad_P3_Intake1">
         
		  <Circle className="circle-magic-kingdom" center={Saidabad_P3_Intake1} radius={200} />

	
        </LayersControl.Overlay>	  


        <LayersControl.Overlay  name="zone 2 BND">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz64} />
            <Tooltip sticky>zone 6</Tooltip>

          </LayerGroup>
        </LayersControl.Overlay>


      </LayersControl>
	  
	  
	<LayersControl position="topleft" collapsed={true}	 >

         <LayersControl.Overlay name="Saidabad_P3_Intake">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Saidabad_P3_Intake} />
			</FeatureGroup>
        </LayersControl.Overlay>
		
        <LayersControl.Overlay name="Saidabad_plant">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Saidabad_plant} />
			</FeatureGroup>
        </LayersControl.Overlay>		

	   
       	
		
      </LayersControl>  
	  
	  <ControllingGroup />	 
	  
	  
    </MapContainer >



  );
}

export default Zone6;
