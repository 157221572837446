import React from "react";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup, LayersControl, Circle, Polygon, LayerGroup, Tooltip } from 'react-leaflet';
import './App.css';


import { Icon } from "leaflet";
import south from './south.json'
import zone from './SLS_point.json'
import SWTP_Valve from "./data/water_treatment/SWTP_Valve.json";
import SWTP_pipe from "./data/water_treatment/SWTP_pipe.json";
import SKWTP_dtw from "./data/water_treatment/SKWTP_dtw.json";
import SWTP_loc from "./data/water_treatment/SWTP_loc.json";


//import './pic/img_girl.jpg';
//var pic = img_girl.jpg;
//import { FeatureGroup } from 'react-leaflet';
//import { EditControl } from "react-leaflet-draw";
import {
	bz61, bz62,bz63,bz64, bz65, bz66,bz67,bz69,bz610,bz68,
Arambag_SLS,Basaboo_SLS,sadgate_SLS, Nilkhet_Newmarket_SLS,
Hazaribag_SLS,
Nawabgonj_SLS,
Azimpur_SLS,
Armanitola_SLS,
Dholaikhal_Narinda_SLS,
Saidabad_Swamibag_SLS,
Goran_SLS,
Motherteck_SLS,
Mohakhali_DOHS,
Zigatola_SLS,
Moghbazar_SLS,
Banani_DOHS,
Dhaka_Medical_College,
Faridabad_SLS,
Mirpur_DOHS,
Old_Airport_SLS,
Narinda_Central_PS,
Japan_Garden_City_SLS,
Tejgaon,
Rampura_Central_PS,

DSTP,
Mirpur_STP,
Rayerbazar_STP,
Uttara_STP,
Pagla_STP,
 
  animalKingdomPolygon,
  hollywoodStudiosPolygon,
  blizzardBeachPolygon,
  epcotCenter1,
  magicKingdomCenter2,
  magicKingdomCenter1,
} from './data1';
//import React, { useState } from "react";
//import Header from "components/Header";


//import "leaflet/dist/leaflet.css";
//import "leaflet-draw/dist/leaflet.draw.css";
//import ExternalInfo from "components/ExternalInfo";



//import * as DTWall from "./data/Biometric.json";
import * as data_z1 from "./data/data_z1.json";
import * as data_z2 from "./data/data_z2.json";
import * as data_z3 from "./data/data_z3.json";
import * as data_z4 from "./data/data_z4.json";
import * as data_z5 from "./data/data_z5.json";
import * as data_z6 from "./data/data_z6.json";
import * as data_z7 from "./data/data_z7.json";
import * as data_z9 from "./data/data_z9.json";
import * as data_z10 from "./data/data_z10.json";

const skater1 = new Icon({
  iconUrl: "/Pin.gif",
  iconSize: [15, 15]
});
//import * as xxx from "./data/bz6.json";
const SLS_lift = new Icon({
  iconUrl: "/pride-rainbow.gif",
  iconSize: [25, 25]
});
const skater = new Icon({
  iconUrl: "/giphy_cir.gif",
  iconSize: [15, 15]
});

const DTW = new Icon({
  iconUrl: "/DTW.gif",
  iconSize: [15, 15]
});





//var legend1 = legend ;
//import Header from "./components/Header";
//import Content from "./components/Content";
//import { Icon } from "leaflet";
//import * as parkData1 from "./data/skateboard-parks.json";

//import * as DTW2 from "./data/output.js";
//var parkData = parkData1;
const DTW1 = data_z1;
const DTW2 = data_z2;
const DTW3 = data_z3;
const DTW4 = data_z4;
const DTW5 = data_z5;
const DTW6 = data_z6;
const DTW7 = data_z7;
const DTW9 = data_z9;
const DTW10 = data_z10;

//const DTW = DTWall;
//console.log(parkData)


function Zone6() {




  // const arr = DTW2;

  //const result = arr.filter(park => park.properties.ZONEID === 5);



  //const DTW = DTW1.filter(park => park.properties.name === "Gulshan-7")
  return (




    <MapContainer center={[23.771886, 90.283460]} zoom={14}  scrollWheelZoom={true}>
      <TileLayer
        url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
        maxZoom={20}
        subdomains={['mt1', 'mt2', 'mt3']}
      />


  
      {/*

      
        <EditControl
          position='topright'
          onEdited={this._onEditPath}
          onCreated={this._onCreate}
          onDeleted={this._onDeleted}
          draw={{
            rectangle: false
          }}
        />
      
      <GeoJSON attribution="&copy; credits due..." data={...} />
      {DTW.features.map(park => (

        <Marker key={park.properties.OBJECTID} position={[park.properties.X,
        park.properties.Y]}>
          <Popup position={[park.properties.X,
          park.properties.Y]}>
            <div >
              <h1 style={{ color: "red" }}>DEVICE LOCATION!</h1>
              <h2>{park.properties.name}</h2>
              <h1 style={{ color: "red" }}>CLUSTER DTW!</h1>
              <h2>{park.properties.LABEL}</h2>

            </div>

          </Popup>
        </Marker>
      ))}
    */}
      <LayersControl position="topright">

        <LayersControl.BaseLayer  name="google street">

          <TileLayer
            url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />

        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer checked name="google satelite">

          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Feature group">
          <Circle
            center={[51.51, -0.08]}
            pathOptions={{ color: 'green', fillColor: 'green' }}
            radius={100}
          />
        </LayersControl.Overlay>
		
		
		
	        <LayersControl.Overlay checked name="Location_WTP">
          <LayerGroup>
		   
            <Tooltip sticky>zone 6</Tooltip>
          {SWTP_loc.features.map((arcade1, index) => (
            <Marker
              key={arcade1.properties['start']}
              position={[arcade1.geometry.coordinates[1], arcade1.geometry.coordinates[0]]}icon={SLS_lift}>
              <Popup>
              
				
				<div >
                    <h1 style={{ color: "red" }}>WTP Name!</h1>
                    <h2>{arcade1.properties.Name}</h2>
                    <h1 style={{ color: "red" }}>!</h1>
                    <h2>{arcade1.properties.Pipe_Diameter}</h2>
					<a href={arcade1.properties.Nearby_Bui}><img src={arcade1.properties.Nearby_Bui } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
		  
          </LayerGroup>
        </LayersControl.Overlay>	
		
		
		
		
		
		
		
		        <LayersControl.Overlay checked name="Valve_WTP">
          <LayerGroup>

            
            <Tooltip sticky>zone 6</Tooltip>
          {SWTP_Valve.features.map((arcade, index) => (
            <Marker
              key={arcade.properties['start']}
              position={[arcade.geometry.coordinates[1], arcade.geometry.coordinates[0]]}icon={skater}>
              <Popup>
                
				
				<div >
                    <h1 style={{ color: "red" }}>Valve Type!</h1>
                    <h2>{arcade.properties.Type}</h2>
                    <h1 style={{ color: "red" }}> Diameter (mm )!</h1>
                    <h2>{arcade.properties.Diameter}</h2>
					<a href={arcade.properties.Land_Mark}><img src={arcade.properties.Land_Mark } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay  checked name="DTW">
          <LayerGroup>
		  
            
            <Tooltip sticky>zone 6</Tooltip>
            {SKWTP_dtw.features.map((SLS, index) => (
            <Marker
              key={SLS.properties['start']}
              position={[SLS.geometry.coordinates[1], SLS.geometry.coordinates[0]]}icon={DTW}
            >
              <Popup>
                {SLS.properties.SLS_Name1}
                <br />
                {SLS.properties['SLS_Name1']}
				
				<div >
                    <h1 style={{ color: "red" }}>DTW Name!</h1>
                    <h2>{SLS.properties.status}</h2>
                    <h1 style={{ color: "red" }}>Zone!</h1>
                    <h2>{SLS.properties.Zone}</h2>
					
                  </div>
              </Popup>
            </Marker>
          ))}
			
			
          </LayerGroup>
		  
		  
        </LayersControl.Overlay>
		
		

        


<LayersControl.Overlay checked name="IRP_I">       
		  <Circle className="circle-magic-kingdom" center={magicKingdomCenter2} radius={100} />
		   </LayersControl.Overlay>
		   <LayersControl.Overlay checked name="IRP_II">         
		  <Circle className="circle-magic-kingdom" center={epcotCenter1} radius={100} />	
        </LayersControl.Overlay>	  


	
	<LayersControl.Overlay checked name="Transmission Line">
          <LayerGroup>	

		 //<GeoJSON attribution="&copy; credits due..." data={SWTP_pipe} /> 
<GeoJSON
    data={SWTP_pipe}
    onEachFeature={(feature, layer3) => {
        		
		if (feature.properties && feature.properties.Dia) {
    layer3.bindPopup('<b>' + "Diameter:" +'&nbsp;' +feature.properties.Dia + '</b><br>'
                         + "Length:" +'&nbsp;' + feature.properties.LENGTHM + '<br>'
                        + "Material:" +'&nbsp;' + feature.properties.MATERIAL+'<br>'
						+ "Plant:" +'&nbsp;' + feature.properties.GROUPID);
  }
		
    }}	/> 


		 
			   </LayerGroup>	 
	        </LayersControl.Overlay>

		











      </LayersControl>
    </MapContainer >



  );
}

export default Zone6;
